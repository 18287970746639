import { logout } from "components/actions/AppActions";
import SidebarItem from "../SidebarItem";

// icons
import {
  FaUsers,
  FaUserAlt,
  FaQuestion,
  FaUserPlus,
  FaUserTie,
  FaCalendarAlt,
} from "react-icons/fa";
import {
  MdOutlineQuestionAnswer,
  MdQuestionAnswer,
  MdListAlt,
  MdList,
  MdTask,
  MdDashboard,
  MdTimer,
  MdLogout,
  MdShare,
} from "react-icons/md";
import { useDispatch } from "react-redux";

export default function AdminMenu({ shrunk }) {
  const dispatch = useDispatch();

  return (
    <>
      {/* Dashboard */}
      <SidebarItem
        icon={<MdDashboard />}
        title="Dashboard"
        href="/"
        {...{ shrunk }}
      />

      {/* Reuniões Online */}
      <SidebarItem
        icon={<FaCalendarAlt />}
        title="Reuniões Online"
        href="/reunioes"
        {...{ shrunk }}
      />

      {/* Agenda */}
      <SidebarItem
        icon={<FaCalendarAlt />}
        title="Calendário"
        href="/agenda"
        {...{ shrunk }}
      />

      {/* Compartilhamento */}
      <SidebarItem
        icon={<MdShare />}
        title="Compartilhamento"
        href="/compartilhamento"
        {...{ shrunk }}
      />

      {/* Cadastros */}
      <SidebarItem
        icon={<FaUserPlus />} // Ícone representando cadastros
        title="Cadastros"
        href="/cadastros"
        {...{ shrunk }}
      />

      {/* Logout */}
      <SidebarItem
        onClick={() => {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          dispatch(logout());
        }}
        icon={<MdLogout />}
        title="Sair"
        {...{ shrunk }}
      />
    </>
  );
}
