import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./GoogleStyleCalendar.css";
import axios from "axios";
import "moment/locale/pt-br";
import { API_URL } from "utilities/variables";

moment.locale("pt-BR");
const localizer = momentLocalizer(moment);

const GoogleStyleCalendar = () => {
  const [events, setEvents] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [newEvent, setNewEvent] = useState({
    name: "",
    start: "",
    end: "",
    description: "",
  });
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchEvents = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(API_URL + "meets", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const loadedEvents = response.data.map((meet) => ({
        id: meet.id,
        title: `${new Date(meet.date).toLocaleTimeString("pt-BR", {
          hour: "2-digit",
          minute: "2-digit",
        })} - ${meet.name}`,
        start: new Date(meet.date),
        end: new Date(meet.date_end),
        description: meet.description,
        source: "backend",
        is_meeting: meet.is_meeting, // Adicionando a propriedade is_meeting/////
      }));
      setEvents(loadedEvents);
    } catch (error) {
      console.error("Erro ao carregar eventos:", error.response?.data || error.message);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const addEventToBackend = async () => {
    if (newEvent.name && newEvent.start && newEvent.end) {
      // Validação de conflito de horários
      const hasConflict = events.some((event) => {
        const newStart = new Date(newEvent.start);
        const newEnd = new Date(newEvent.end);
        const eventStart = new Date(event.start);
        const eventEnd = new Date(event.end);

        return (
          (newStart >= eventStart && newStart < eventEnd) || // Início no meio de outro evento
          (newEnd > eventStart && newEnd <= eventEnd) || // Fim no meio de outro evento
          (newStart <= eventStart && newEnd >= eventEnd) // Novo evento engloba outro evento
        );
      });

      if (hasConflict) {
        alert("Conflito de horário: já existe uma reunião nesse intervalo!");
        return;
      }

      const token = localStorage.getItem("token");
      const eventData = {
        name: newEvent.name,
        date: newEvent.start,
        date_end: newEvent.end,
        description: newEvent.description,
        is_meeting: false, ////////
      };

      try {
        await axios.post(API_URL + "meets", eventData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Recarregar eventos
        fetchEvents();

        // Limpar formulário
        setNewEvent({ name: "", start: "", end: "", description: "" });
        setShowForm(false);
      } catch (error) {
        console.error("Erro ao salvar evento:", error.response?.data || error.message);
      }
    }
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
  };

  return (
    <div className="calendar-container">
      <div className="header-container">
        <button className="btn-add-event" onClick={() => setShowForm(!showForm)}>
          + Adicionar Evento
        </button>
      </div>

      {showForm && (
        <div className="form-container">
          <h3>Adicionar Novo Evento</h3>
          <input
            type="text"
            placeholder="Nome"
            value={newEvent.name}
            onChange={(e) => setNewEvent({ ...newEvent, name: e.target.value })}
          />
          <label>Data e hora de início:</label>
          <input
            type="datetime-local"
            value={newEvent.start}
            onChange={(e) => setNewEvent({ ...newEvent, start: e.target.value })}
          />
          <label>Data e hora de término:</label>
          <input
            type="datetime-local"
            value={newEvent.end}
            onChange={(e) => setNewEvent({ ...newEvent, end: e.target.value })}
          />
          <label>Descrição:</label>
          <textarea
            placeholder="Descrição"
            value={newEvent.description}
            onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
          />
          <button className="btn-save-event" onClick={addEventToBackend}>
            Salvar Evento
          </button>
          <button className="btn-cancel-event" onClick={() => setShowForm(false)}>
            Cancelar
          </button>
        </div>
      )}

      {isModalOpen && selectedEvent && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>Detalhes da Reunião</h3>
            <p><strong>Nome:</strong> {selectedEvent.title}</p>
            <p><strong>Data de Início:</strong> {selectedEvent.start.toLocaleString()}</p>
            <p><strong>Data de Término:</strong> {selectedEvent.end.toLocaleString()}</p>
            <p><strong>Descrição:</strong> {selectedEvent.description}</p>
            <button className="btn-close" onClick={closeModal}>
              
            </button>
          </div>
        </div>
      )}

      <div className="legend-container">
        <div className="legend-item">
          <span className="legend-color" style={{ backgroundColor: "#1c99c7" }}></span>
          Eventos do Formulário
        </div>
        <div className="legend-item">
          <span className="legend-color" style={{ backgroundColor: "#28a745" }}></span>
          Reuniões Online
        </div>
      </div>

      <Calendar
        localizer={localizer}
        events={events}
        defaultView="month"
        views={["month", "week", "day"]}
        style={{ minHeight: "600px", height: "auto" }}
        eventPropGetter={(event) => {
          // Condicional para o fundo baseado em 'is_meeting'
          const backgroundColor = event.is_meeting === false ? "#1c99c7" : "#28a745";
          return {
            style: {
              backgroundColor,
              color: "#ffffff",
              borderRadius: "5px",
              border: "none",
              fontWeight: "bold",
              padding: "5px",
              fontSize: "14px",
              cursor: "pointer",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            },
          };
        }}
        messages={{
          next: "Próximo",
          previous: "Anterior",
          today: "Hoje",
          month: "Mês",
          week: "Semana",
          day: "Dia",
        }}
        onSelectEvent={handleSelectEvent}
      />
    </div>
  );
};

export default GoogleStyleCalendar;
