import React, { useState } from "react";
import {
  Form,
  FormControl as Input,
  InputGroup,
  Button,
} from "react-bootstrap";
import { SaveRounded } from '@material-ui/icons';
import axios from 'axios';
import { API_URL } from "utilities/variables";  // Importando a variável API_URL

export function UserRegistrationForm() {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessages([]);
    setSuccessMessage("");

    // Verificar se as senhas coincidem
    if (password !== confirmPassword) {
      setErrorMessages(["As senhas não coincidem."]);
      return;
    }

    const userData = {
      email,
      username,
      password,
    };

    // Recuperando o token do localStorage
    const token = localStorage.getItem("token");

    if (!token) {
      setErrorMessages(["Token não encontrado. Por favor, faça login."]);
      return;
    }

    // Usando a variável API_URL para fazer a requisição
    axios
      .post(`${API_URL}add`, userData, {
        headers: {
          Authorization: `Bearer ${token}`,  // Adicionando o token ao cabeçalho
        },
      })
      .then((response) => {
        setSuccessMessage("Usuário criado com sucesso!");
        setEmail("");
        setUsername("");
        setPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          setErrorMessages(error.response.data.errors || ["Erro desconhecido ao criar o usuário."]);
        } else {
          setErrorMessages(["Erro na conexão com o servidor."]);
        }
      });
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="py-5 px-3">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4 style={{ fontSize: "1.5rem", color: "black" }}>
              Cadastrar Usuário
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              Preencha os campos abaixo para criar uma nova conta.
            </p>
            <br />

            {/* Exibir mensagens de erro */}
            {errorMessages && errorMessages.length > 0 && (
              <div
                className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                role="alert"
              >
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setErrorMessages([])}
                ></button>
                {errorMessages.map((error, index) => (
                  <p key={index} style={{ marginBottom: 0 }}>
                    {error}
                  </p>
                ))}
              </div>
            )}

            {/* Exibir mensagem de sucesso */}
            {successMessage && (
              <div
                className="alert alert-success alert-dismissible fade show mb-0 w-100"
                role="alert"
              >
                <p style={{ marginBottom: 0 }}>{successMessage}</p>
              </div>
            )}

            {/* Formulário de cadastro */}
            <Form onSubmit={handleSubmit}>
              <InputGroup className="mb-3">
                <div className="flex fdcolumn flex1 margin-bottom">
                  <Form.Label>Email</Form.Label>
                  <Input
                    type="email"
                    placeholder="Digite seu email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </InputGroup>

              <InputGroup className="mb-3">
                <div className="flex fdcolumn flex1 margin-bottom">
                  <Form.Label>Nome do Usuário</Form.Label>
                  <Input
                    type="text"
                    placeholder="Digite seu nome de usuário"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </div>
              </InputGroup>

              <InputGroup className="mb-3">
                <div className="flex fdcolumn flex1 margin-bottom">
                  <Form.Label>Senha</Form.Label>
                  <Input
                    type="password"
                    placeholder="Digite sua senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
              </InputGroup>

              <InputGroup className="mb-3">
                <div className="flex fdcolumn flex1 margin-bottom">
                  <Form.Label>Confirmação de Senha</Form.Label>
                  <Input
                    type="password"
                    placeholder="Confirme sua senha"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>
              </InputGroup>

              <Button
                type="submit"
                variant="primary"
                className="me-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <SaveRounded />
                Salvar
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
