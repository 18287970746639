import { useState } from "react";
import { FaUsers } from "react-icons/fa";
import { MdPublic, MdPalette } from "react-icons/md";
import "./comp.css";

export default function Compartilhamento() {
    const [isPublic, setIsPublic] = useState(false);
    const [shareWith, setShareWith] = useState([]);
    const [participantInput, setParticipantInput] = useState('');
    const [calendarUrl, setCalendarUrl] = useState("https://example.com");
    const [highlightColor, setHighlightColor] = useState("#1a73e8");

    const addParticipant = () => {
        if (participantInput.trim()) {
            setShareWith([...shareWith, participantInput.trim()]);
            setParticipantInput('');
        } else {
            alert("Por favor, insira um participante válido.");
        }
    };

    const removeParticipant = (index) => {
        const updatedList = shareWith.filter((_, i) => i !== index);
        setShareWith(updatedList);
    };

    const togglePublic = () => setIsPublic(!isPublic);

    return (
        <div className="compartilhamento-container">
            <h1>Configurações da Agenda</h1>

            {/* Disponibilizar ao Público */}
            <div className="section">
                <h2><MdPublic /> Disponibilizar ao Público</h2>
                <label>
                    <input type="checkbox" checked={isPublic} onChange={togglePublic} />
                    Tornar agenda pública
                </label>
                {isPublic && (
                    <div>
                        URL pública: <a href={calendarUrl} target="_blank" rel="noopener noreferrer">{calendarUrl}</a>
                    </div>
                )}
            </div>

            {/* Paleta de Destaque */}
            <div className="section">
                <h2><MdPalette /> Cor de Destaque</h2>
                <input
                    type="color"
                    value={highlightColor}
                    onChange={(e) => setHighlightColor(e.target.value)}
                />
            </div>

            {/* Compartilhar com Pessoas */}
            <div className="section">
                <h2><FaUsers /> Compartilhar com Pessoas</h2>
                <div>
                    <input
                        type="text"
                        placeholder="Adicionar participantes (e-mail)"
                        value={participantInput}
                        onChange={(e) => setParticipantInput(e.target.value)}
                    />
                    <button onClick={addParticipant}>Adicionar</button>
                </div>
                <ul>
                    {shareWith.map((participant, index) => (
                        <li key={index}>
                            <span>{participant}</span>
                            <button className="remove-button" onClick={() => removeParticipant(index)}>Remover</button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
