import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Router,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from "react-router-dom";
import GuestRoutes from "./GuestRoutes";

import { useSelector } from "react-redux";
import Dashboard from "pages/private/Dashboard";
import { HomeView } from "pages/private/Home";
import HomePage from "pages/public/Home";
import { MeetsView } from "pages/private/Meets/list";
import Calendario from "pages/private/Calendario";
import { UserRegistrationForm as Cadastros } from 'pages/private/Cadastros';
import Compartilhamento from "pages/private/Compartilhamento"; // Importando a página de Compartilhamento
import { MeetForm } from "pages/private/Meets/form";
import { MeetsManagerView } from "pages/private/Meets/manager";

function AppStack(props) {
  return <HomeView />;
}

export default function AppNav() {
  const { user, token } = useSelector((store) => store.AppReducer);

  function Root() {
    return user && token ? <AppStack /> : <GuestRoutes />;
  }

  const router = createBrowserRouter([
    {
      path: "*",
      element: <Root />,
      errorElement: <>Erro</>,
      children: user && token
        ? [
            // Definindo rotas para usuários autenticados
            {
              path: "",
              element: <Dashboard />,
            },
            {
              path: "reunioes",
              element: <MeetsView />,
            },
            {
              path: "agenda",
              element: <Calendario />,
            },
            {
              path: "compartilhamento", // Adicionando a rota de compartilhamento
              element: <Compartilhamento />,
            },
            {
              path: "cadastros", // Adicionando a rota de cadastros
              element: <Cadastros />,
            },
            {
              path: "reunioes/create",
              element: <MeetForm />,
            },
            {
              path: "reunioes/edit/:id",
              element: <MeetForm editMode />,
            },
            {
              path: "reunioes/:id",
              element: <MeetsManagerView />,
            },
          ]
        : [],
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}
